import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import privacyImg from '../assets/img/privacy-policy-service-documents-terms-use-concept.jpg';
import '../assets/css/privacyPolicy.css';

const PrivacyPolicy = () => {

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
        document.body.style.overflow = 'auto'; // Enable scrolling
    }, []);

    return (
        <>
            <Helmet>
                <title>TechBlume | Privacy Policy</title>
            </Helmet>
            <div className='privacy-policy'>
                <div className='privacy-page1'>
                    <img src={privacyImg} alt="Privacy Policy Banner" />
                    <section>
                        <h1>Understanding Our Commitment to Privacy</h1>
                        <p>
                            Our privacy policy is designed to inform and reassure individuals about the collection, use, and safeguarding of their personal information online. We are committed to ensuring transparency in how we handle personal data and to protecting the privacy of our users at every step.
                        </p>
                    </section>
                </div>

                <div className='privacy-page2'>

                    <h1>TechBlume Privacy Policy</h1>
                    <p>
                        Effective: 2024
                    </p>

                    <h2>About This Privacy Policy</h2>
                    <p>
                        “We,” “us,” or “our” refers to <b>TECHBLUME SOFTWARE SOLUTIONS INC</b>, located at 16192 Coastal Hwy, Lewes, DE, US, 19958-3608. <br />
                        At TechBlume, we are deeply committed to protecting your privacy and safeguarding the personal information you share with us. We understand that trust is the foundation of our relationship with our users, and we take our responsibility to protect your personal data very seriously. This Privacy Policy is designed to give you a clear understanding of how we collect, use, and protect your data when you engage with our online bootcamp services. Whether you are enrolling in a course, progressing through your curriculum, or simply visiting our platform, we strive to provide transparency and control over your personal information. Our goal is to create a safe, personalized, and efficient learning experience while upholding the highest standards of data protection.

                    </p>

                    <section>
                        <h2>1. Information We Collect</h2>
                        <ul>
                            <li>
                                <b>Personal Information</b>: Includes your name, email address, phone number, date of birth, and payment information.
                            </li>
                            <li>
                                <b>Account and Course Data</b>: Information about the courses you enroll in, your course progress, assessments, and certificates.
                            </li>
                            <li>
                                <b>Usage Data</b>: Includes data about your interaction with our platform, such as login times, browser type, IP address, device details, and cookies.
                            </li>
                            <li>
                                <b>Feedback and Surveys</b>: Data provided through surveys or feedback forms regarding our courses and services.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2>2. How We Use Your Information</h2>
                        <ul>
                            <li>
                                <b>Provide Services</b>: We use your data to create and manage your account, enroll you in courses, and enable access to course materials, ensuring a smooth learning experience.
                            </li>
                            <li>
                                <b>Personalize Your Experience</b>: By analyzing your learning progress and preferences, we tailor content, recommendations, and resources to provide a more customized experience.
                            </li>
                            <li>
                                <b>Communicate with You</b>: We may send updates on course progress, inform you of new course offerings, provide newsletters, and respond promptly to your inquiries.
                            </li>
                            <li>
                                <b>Improve Our Services</b>: We assess trends, identify usage patterns, and gather user feedback to continuously enhance our platform's functionality and course content.
                            </li>
                            <li>
                                <b>Comply with Legal Obligations</b>: We may use your information for purposes such as fraud prevention, enforcing our Terms of Service, and complying with applicable laws and regulations.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Sharing Your Information</h2>
                        <ul>
                            <li>
                                <b>Service Providers</b>: We may share your information with trusted third-party vendors who assist in payment processing, data analytics, and marketing. These vendors are obligated to protect your information and only use it for designated purposes.
                            </li>
                            <li>
                                <b>Legal Requirements</b>: Your information may be shared as required by law or in response to lawful requests, such as subpoenas, court orders, or other legal processes.
                            </li>
                            <li>
                                <b>Business Transfers</b>: If TechBlume undergoes a merger, acquisition, or asset sale, your information may be transferred to the new entity as part of the transaction.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Data Security</h2>
                        <p>
                            We implement industry-standard security measures to protect your data. However, please note that no online platform can guarantee complete security. While we take all reasonable steps to safeguard your information, we cannot be held responsible for unauthorized access beyond our control.
                        </p>
                    </section>

                    <section>
                        <h2>5. Your Rights</h2>
                        <ul>
                            <li>
                                <b>Access</b>: You have the right to access the information we hold about you.
                            </li>
                            <li>
                                <b>Update or Delete</b>: You can update or delete your personal data by accessing your account settings or contacting us directly.
                            </li>
                            <li>
                                <b>Opt-Out</b>: You may opt out of marketing communications by following the unsubscribe link in our emails.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2>6. Cookies and Tracking Technologies</h2>
                        <p>
                            We use cookies to enhance user experience, track platform usage, and deliver personalized content. You can manage your cookie preferences through your browser settings to control how cookies interact with your data.
                        </p>
                    </section>

                    <section>
                        <h2>7. Children’s Privacy</h2>
                        <p>
                            Our services are not directed to children under the age of 13. We do not knowingly collect or store information from children under 13 without parental consent.
                        </p>
                    </section>

                    <section>
                        <h2>8. Changes to This Policy</h2>
                        <p>
                            We may update this Privacy Policy periodically. Any changes will be posted here, and we will notify you of significant changes to ensure transparency regarding your data privacy.
                        </p>
                    </section>

                    <section>
                        <h2>9. Contact Us</h2>
                        <p>
                            If you have any questions about this Privacy Policy or how your data is handled, please contact us at:
                     <br /><br />
        <b>TechBlume Support</b><br />
        Address: 16192 Coastal Hwy, Lewes, DE, US, 19958-3608<br />
        Email: <a href="mailto:support@techblume.in">support@techblume.in</a><br />
        Phone: +1 (302) 947-8773
    </p>
                    </section>

                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
