import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet';
// import { useRef } from 'react'
// import emailjs from '@emailjs/browser';
import AlertContactForm from './AlertContactForm';
import '../assets/css/WebDevelopment.css'
import cs from '../assets/img/cs.png'
import Img24 from '../assets/img/Img24.png'
import Img25 from '../assets/img/Img25.png'
import Img26 from '../assets/img/Img26.png'
import Img27 from '../assets/img/Img27.png'
import Technologies3 from '../assets/img/ui-ux-techs.png'
import uiUxRoadmap from '../assets/img/UX-roadmap.png'
import { Link } from 'react-router-dom'

const UxDesign = () => {

  const [toggle, setToggle] = useState(true);

  
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);

    // Check if form was previously submitted
    // const formSubmitted = localStorage.getItem('formSubmitted');
    // if (!formSubmitted) {
    //   setToggle(true); // Show the form if not submitted
    //   document.body.style.overflow = 'hidden'; // Disable scrolling
    // } else {
    //   setToggle(false); // Hide form if already submitted
    //   document.body.style.overflow = 'auto'; // Enable scrolling
    // }
  }, []);

  return (
   <>

<Helmet>
        <title>Techblume | Courses - UI/UX Design</title>
      </Helmet>

       
{toggle && (
      <AlertContactForm/>

    )}
      {/* End Contact Form */}
    <div className='wd-course'>
       <div className="wd-section1">
       <img src={cs} alt="wd" />
           <section>
            <h1>Launch Your Journey as a <span>UI/UX Design Specialist</span></h1>
            <p>Start your UI/UX journey and explore the exciting world of crafting intuitive, user-centered designs. Whether you're a beginner or looking to enhance your design expertise, UI/UX design opens up endless opportunities to create impactful user experiences, improve digital products, and elevate user satisfaction. With the right guidance and learning resources, you can develop key skills in wireframing, prototyping, and user research, paving the way for a successful and creative career in this evolving design field.</p>
            </section>
       </div>

       <div className="wd-section2">
        <h2>UI/UX Learning Path</h2>
        <img src={uiUxRoadmap} alt="ds-roadmap" />
       </div>
    </div>

    <div className="wd-section5">
        <main>
        <section>
            <h1>Schedule Your Free Consultation</h1>
            <p>Need Help? Get Your Questions Answered Now!</p>
            <p>Reach out to our Academic Counsellor today for answers to all your questions!</p>
            <Link to='/bookings'>Book your slot now!</Link>
        </section>
        <img src={Img27} alt="Img27" />
        </main>
    </div>

    <div className="wd-course">
    <div className='wd-section3'>
          <h2>Course Overview</h2>
          <div>
            <img src={Img24} alt="Img24" />
            <section>
                <h1><span>One-on-One</span> Discussions</h1>
                <p>Students will have the opportunity for one-on-one discussions with experienced instructors to receive personalized guidance and feedback throughout the course.</p>
            </section>
          </div>

          <div>
           
            <section>
            <h1>Innovative <span>Project-Focused</span> Learning</h1>
                <p>At the core of our educational philosophy is Innovative Project-Focused Learning, a dynamic approach designed to immerse students in real-world applications from the start.</p>
            </section>
            <img src={Img25} alt="Img25" />
          </div>

          <div>
            <img src={Img26} alt="Img26" />
            <section>
            <h1><span>Career</span> Development Assistance</h1>
               
                <p>Navigating your career path can be challenging, but with the right guidance, you can unlock new opportunities and achieve your professional goals.</p>
            </section>
          </div>
       </div>

       <div className='wd-section4'>
            <h1>Tech Stack and Tools Covered</h1>
             <img src={Technologies3} alt="" />
       </div>
    </div>
   </>

   
  )
}

export default UxDesign
