import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import '../assets/css/ThankYou.css'
import thumbsUp from '../assets/img/thumbs-up.png'
import { Link } from 'react-router-dom';
import Footer from './Footer';

const StyledTable = ({ state }) => {
  
  return (
    <TableContainer component={Paper} elevation={3} style={{ borderRadius: '8px', overflow: 'hidden',maxWidth:'400px',margin:'1rem auto' }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell><b>Name</b></TableCell>
            <TableCell>{state.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><b>Email</b></TableCell>
            <TableCell>{state.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><b>Slot</b></TableCell>
            <TableCell>{`${state.date}, ${state.time}, ${state.zone}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};



const ThankYou = () => {
  const location = useLocation();
  const  {state}  = location; // Access the passed data
  console.log(state);

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto'; // Enable scrolling
  }, []);
  
  return (
   <>
    <div className='thankyou-container'>
      <div className='sub-container1'>
          <div className='circle'>
             <img src={thumbsUp} alt="thumbsUp" />
          </div>
      </div>
      <div className='sub-container2'>
   <h2>Thank You!</h2>
   <p>Your meeting slot with Techblume for the Software Development Training has been successfully booked</p>
   <h3>Booking Details</h3>
   
   <StyledTable state={state} />
      </div>

      <div className='sub-container3'>
         <Link to='/'>Back to Home Page</Link>
      </div>
      <Footer/>
    </div>
   
   </>
  )
}

export default ThankYou
