import React from 'react'
import '../assets/css/Footer.css'
import email from '../assets/img/email.svg'
import phone from '../assets/img/phone.svg'
import Logo from '../assets/img/Footer-logo.png'
// import address from '../assets/img/address-pin-location-svgrepo-com.png'
import FaceBookIcon from '../assets/img/FacebookIcon.png'
import LinkedInIcon from '../assets/img/LinkedInIcon.png'
import WhatsAppIcon from '../assets/img/WhatsAppIcon.png'
import InstaIcon from '../assets/img/InstaIconSvg.png'
import XIcon from '../assets/img/XIconSvg.png'
import YoutubeIcon from '../assets/img/YoutubeIconSvg.png'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
   <div className='footer'>
      <div className='footer-section'>
      <div className="logo">
          <img src={Logo} alt="Logo" />
          {/* <h1>Techblume</h1> */}
        </div>
        <div className='footer-main1'>
           <p>Techblume is an innovative e-learning platform committed to delivering high-quality, practical education to learners worldwide. We empower students to master cutting-edge technologies and achieve their career goals.</p>

           <div className="footer-main2">
        <section>
            <h1>Get in touch</h1>
            <p><a href="mailto:support@techblume.in"><img src={email} alt="email" /> support@techblume.in</a></p>
            <p><a href="tel:13022328049"><img src={phone} alt="phone" /> +1 3022328049</a></p>
            {/* <p><a href="tel:919686430320"><img src={phone} alt="phone" /> +91 9686430320</a> - India</p> */}
           </section>
        </div>

           <main>
           <ul>
            <li><h1>Quick Links</h1></li>
           <li>
            <Link to='/'>Home</Link>
           </li>
           <li>
            <Link to='/about'>About</Link>
           </li>
           <li>
            <Link to='/projects'>Projects</Link>
           </li>
           <li>
            <Link to='/contact'>Contact Us</Link>
           </li>
           <li>
            <Link to='/student-certificates'>Certificates</Link>
           </li>
           <li>
            <Link to='/bookings'>Book Now</Link>
           </li>
           <li>
            <Link to='/privacy-policy'>Privacy Policy</Link>
           </li>
           </ul>

           {/* <ul>
            <li></li>
           <li>
            <Link to='/'>Home</Link>
           </li>
           <li>
            <Link to='/about'>About</Link>
           </li>
           <li>
            <Link to='/projects'>Projects</Link>
           </li>
           <li>
            <Link to='/contact'>Contact Us</Link>
           </li>
           <li>
            <Link to='/bookings'>Book Now</Link>
           </li>
           </ul> */}
           </main>
        </div>

        {/* <div className="footer-main2">
        <section>
            <h1>Get in touch</h1>
            <p><a href="mailto:support@techblume.in"><img src={email} alt="email" /> support@techblume.in</a></p>
            <p><a href="tel:919686430320"><img src={phone} alt="phone" /> +91 9686430320</a></p>
            <p><img src={address} alt="whatsapp" /> Bangalore, India</p>
           </section>
        </div> */}

        <div className="socials">
          <a href="https://www.facebook.com/profile.php?id=61567277476660" target='_blank' rel="noreferrer">
            <img src={FaceBookIcon} alt="FaceBookIcon" />
          </a>
          <a href="https://www.linkedin.com/in/techblume-software-solutions" target='_blank' rel="noreferrer">
            <img src={LinkedInIcon} alt="LinkedInIcon" />
          </a>
          {/* <a href="https://wa.me/919686430320" target='_blank' rel="noreferrer">
            <img src={WhatsAppIcon} alt="WhatsAppIcon" />
          </a> */}

          {/* <a href=""></a> */}
          <a href="https://www.instagram.com/tech.blumesoftware/" target='_blank' rel="noreferrer">
            <img src={InstaIcon} alt="InstaIcon" />
          </a>
          <a href="https://x.com/techblume_05" target='_blank' rel="noreferrer">
            <img src={XIcon} alt="XIcon" />
          </a>
          <a href="https://www.youtube.com/shorts/6yvy6ZYqqzs" target='_blank' rel="noreferrer">
            <img src={YoutubeIcon} alt="YoutubeIcon" />
          </a>
        </div>

        <div className="last-section">
          <p>© 2024 Techblume. All rights reserved.</p>
        </div>
      </div>
   </div>
  )
}

export default Footer
