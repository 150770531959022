import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import graduationGirl from '../assets/img/Graduation-student.png';
import certificate1 from '../assets/img/Mia Thomas  - Course Complition Certificate - Techblume_page-0001.jpg'
import certificate2 from '../assets/img/Alexander Davis - Course Complition Certificate - Techblume_page-0001.jpg'
import '../assets/css/privacyPolicy.css';


const Certificates = () => {

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
        document.body.style.overflow = 'auto'; // Enable scrolling
    }, []);

  return (
    <>
    <Helmet>
        <title>Techblume | Student Certificates</title>
    </Helmet>
    <div className='privacy-policy'>
        <div className='privacy-page1'>
            <img src={graduationGirl} alt="Privacy Policy Banner" className='graduation-img'/>
            <section>
                <h1>Certificates of Excellence for Students</h1>
                <p>
                Our certification policy is designed to ensure that students are appropriately recognized for their accomplishments. We are dedicated to issuing accurate and timely certifications, while maintaining transparency throughout the process. Our priority is to ensure that all certificates reflect the true effort and success of our students.
                </p>
            </section>
        </div>

        <div className='certificate-page1'>
            <h1>Course Completion Certificates</h1>
            <section>
                <div>
                    <img src={certificate1} alt="certificate1" />
                    <p>Full Stack Development</p>
                </div>
                <div>
                    <img src={certificate2} alt="certificate2" />
                    <p>Data Science</p>
                </div>
            </section>
        </div>
        </div>
    </>
  )
}

export default Certificates
